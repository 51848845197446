@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,600,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;600;700&display=swap);
*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-user-select: none; /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

input,
textarea {
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #000000;
}
body {
  margin: 0;
  font-family: 'Overpass', 'Roboto', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:disabled {
  opacity: 0.4;
}

.grecaptcha-badge {
  z-index: 4;
}

#sending_circle {
  stroke-width: 10px;
  stroke-dasharray: 360;
  stroke-dashoffset: 360;
  -webkit-animation: drawcircle 1s 0.5s forwards;
          animation: drawcircle 1s 0.5s forwards;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 50%;
          transform-origin: 50%;
}

@-webkit-keyframes drawcircle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes drawcircle {
  to {
    stroke-dashoffset: 0;
  }
}

